import requestCustom from '@/utils/requestCustom'

// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
  return requestCustom({
    url: "/system/dict/data/type/" + dictType,
    method: "get",
  });
}
// 查询用户详细 带实名信息
export function getUserSm(userId) {
  return requestCustom({
    url: '/system/user/sm/' + userId,
    method: 'get'
  })
}
// 登录客户系统
export function loginCustom(data) {
  return requestCustom({
      url: `/login`,
      method: 'post',
      data
  })
}
// 退出客户系统
export function loginOutCustom(data) {
  return requestCustom({
      url: `/logout`,
      method: 'post',
      data
  })
}
// 获取客户端图片验证码
export function captchaImage() {
  
  return requestCustom({
      url: `/captchaImage`,
      method: 'get'
  })
}
// 从客户系统获取个人信息
export function getUserInfo() {
  return requestCustom({
      url: `/system/user/profile/new`,
      method: 'get'
  })
}
// 从客户系统获取IM登录token
export function getImToken() {
  return requestCustom({
      url: `/im/msg/getUserToken`,
      method: 'get'
  })
}
// 将图片上传到自己服务器
export function uploadImg(data) {
  return requestCustom({
      url: `/upload/upHx`,
      method: 'post',
      data
  })
}
// 发送消息到客户服务器
export function sendMsg(data) {
  return requestCustom({
      url: `/im/msg/sendMsg`,
      method: 'post',
      data
  })
}

// 回收群查询群成员信息 groupId
export function getOtherGroupInfo(groupId,fromHxId) {
  return requestCustom({
      url: `/group/info/getAllNumbers?groupId=${groupId}&fromHxId=${fromHxId}`,
      method: 'get'
  })
}

// 回收群查询回收订单信息 groupId
export function getOrderInfo(groupId) {
  return requestCustom({
      url: `/account/krecycle/getInfoByGroupId/${groupId}`,
      method: 'get'
  })
}
// 单聊查询回收订单信息 groupId
export function getOrderByHxId(hxId) {
  return requestCustom({
      url: `/account/krecycle/getOrderByHxId/${hxId}`,
      method: 'get'
  })
}

// 获取聊天快捷语
export function getQuickWords(params) {
  return requestCustom({
      url: `/account/reply/list`,
      method: 'get',
      params
  })
}
// 创建群聊
export function createPublicGroup(data) {
  return requestCustom({
      url: `/im/msg/createPublicGroup`,
      method: 'post',
      data
  })
}
// 会话内搜索聊天记录
export function searchInSession(sessionId,content='',type='text') {
  return requestCustom({
      url: `/msg/history/searchInSession?sessionId=${sessionId}&content=${content}&type=${type}`,
      method: 'get'
  })
}

// 查看上下文
// 会话历史消息记录 sessionId

export function getHistoryMessageCustom(sessionId,id='',type=1,limit=20) {
  return requestCustom({
      url: `/msg/history/recentList?sessionId=${sessionId}&type=${type}&limit=${limit}&id=${id}`,
      method: 'get'
  })
}

// 搜索会话列表记录
export function searchInAllSession(content,formUser,type='text') {
  return requestCustom({
      url: `/msg/history/searchInAllSession?content=${content}&type=${type}&formUser=${formUser}`,
      method: 'get'
  })
}
// 提交用户备注
export function saveOrUpdateOrDel(data) {
  return requestCustom({
      url: `/userRemark/saveOrUpdateOrDel`,
      method: 'post',
      data
  })
}
// 用户备注列表
export function userRemarkList(fromHxId,toHxId='') {
  return requestCustom({
      url: `/userRemark/list?fromHxId=${fromHxId}&toHxId=${toHxId}`,
      method: 'get'
  })
}

// 获取会话列表
export function sessionListList(formUser) {
  return requestCustom({
      url: `/sessionList/list?formUser=${formUser}`,
      method: 'get'
  })
}
// // 解散群
export function rmGroup(data) {
  return requestCustom({
      url: `/im/msg/rmGroup`,
      method: 'post',
      data
  })
}

// // 会话置顶
export function topOrCancel(data) {
  return requestCustom({
      url: `/sessionList/topOrCancel`,
      method: 'post',
      data
  })
}
// 获取群详情信息
export function getGroupDetailInfo(id) {
  return requestCustom({
      url: `/group/info/${id}`,
      method: 'get'
  })
}

// 修改群信息
export function updateGroupSettings(data) {
  return requestCustom({
      url: `/group/info/updateSettings`,
      method: 'post',
      data
  })
}

// 更新群公告
export function updateAnnouncement(data) {
  return requestCustom({
      url: `/group/info/updateAnnouncement`,
      method: 'post',
      data
  })
}

// 获取所有环信用户
export function getHaveHxUserList(keyWords,fromHxId) {
  return requestCustom({
      url: `/im/msg/getHaveHxUserList?keyWords=${keyWords}&fromHxId=${fromHxId}`,
      method: 'get'
  })
}

// 拉用户进群
export function addHxUserName(data) {
  return requestCustom({
      url: `/group/info/addHxUserName`,
      method: 'post',
      data
  })
}
// 退出群聊
export function removeHxUserName(data) {
  return requestCustom({
      url: `/group/info/removeHxUserName`,
      method: 'post',
      data
  })
}
// 设为群主
export function assignGroup(data) {
  return requestCustom({
      url: `/group/info/assignGroup`,
      method: 'post',
      data
  })
}
// 获取系统字典
export function getDict(dictType) {
  return requestCustom({
    url: '/system/dict/data/type/' + dictType,
    method: 'get'
  })
}
// 通知后台创建会话
export function sessionListAdd(data) {
  return requestCustom({
    url: '/sessionList',
    method: 'post',
    data
  })
}

// 群聊查询关联商品信息 groupId
export function getGoodsByHxId(data) {
  return requestCustom({
      url: `/group/info/getGoodsInfoByGroupId?toGroup=${data.toGroup}`,
      method: 'get'
  })
}

// 查询单聊关联的回收列表 /account/backlist/list
export function getBacklistByHxId(params) {
  return requestCustom({
      url: `/account/backlist/list`,
      method: 'get',
      params
  })
}
// 根据角色查询用户列表
export function getSupplyList(roleKey) {
  return requestCustom({
    url: "/system/user/getUserByRoleCode/" + roleKey,
    method: "get",
  });
}
// 业务消息 用消息id查询消息状态
export function getStateByMessageId(params) {
  return requestCustom({
      url: `/msg/history/byMessageIds`,
      method: 'get',
      params
  })
}
// 发送短信提醒
// 通知后台创建会话dict
export function sendSortMsg(params) {
  return requestCustom({
    url: `/nd/login/sendDX`,
    method: 'get',
    params
  })
}

// 查询用户配置的回收游戏
export function getwebGameType(userid) {
  return requestCustom({
    url: `/web/game/Type?cosId=${userid}`,
    method: 'get'
  })
}

// 重置密码
export function SmsforPwd(data) {
  return requestCustom({
    url: `/h5/SmsforPwd`,
    method: 'post',
    data
  })
}

// 发送验证码
export function sendYzm(params) {
  return requestCustom({
    url: `/sms/sendYzm`,
    method: 'get',
    params
  })
}
// 撤回消息 通知服务端 /msg/history/revocation
export function revocationMsg(data) {
  return requestCustom({
    url: `/msg/history/revocation`,
    method: 'post',
    data
  })
}
// 手机号实名校验
export function operatorTwoElements(data) {
  return requestCustom({
    url: `/operatorTwoElements`,
    method: 'post',
    data
  })
}
// 手机号实名校验 列表
export function operatorTwoElementslist(params) {
  return requestCustom({
    url: `/operatorTwoElements/list`,
    method: 'get',
    params
  })
}

// 根据用户id查询环信id
export function getHXUserInfo(params) {
  return requestCustom({
    url: `/V2/H5/getHXUserInfo`,
    method: 'get',
    params
  })
}

// 查询单聊关联用户最近浏览 /account/backlist/list
export function getNearVisibleByHxId(params) {
  return requestCustom({
      url: `/goods/browse/IMList`,
      method: 'get',
      params
  })
}

// 查询单聊关联用户买号订单 
export function getSaleOrderByHxId(params) {
  return requestCustom({
      url: `/saleOrder/IMList`,
      method: 'get',
      params
  })
}
// 查询单聊关联用户鉴定订单  hxusername
export function appraiseOrderIMList(params) {
  return requestCustom({
      url: `/appraiseOrder/web/IMList`,
      method: 'get',
      params
  })
}

// /saleOrder/IMList