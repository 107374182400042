import { createStore } from 'vuex'
import { EaseChatClient } from '@/IM/initwebsdk'
import Conversation from './modules/conversation'
import Contacts from './modules/contacts'
import Message from './modules/message'
import Groups from './modules/goups'
import Orders from './modules/orders'
import QuickWords from './modules/quickwords'
import UserRemark from './modules/userRemark'
import afterSale from './modules/afterSale'
import {
  getUserInfo
} from '@/api/custom'
export default createStore({
    state: {
        loginState: false,
        networkStatus: true,
        isShowWarningTips: true,
        loginUserInfo: {
            hxId: '',
            nickname: '',
            avatarurl:
        'https://download-sdk.oss-cn-beijing.aliyuncs.com/downloads/IMDemo/avatar/Image5.png',
        },
        loginUserOnlineStatus: '',
        customUserInfo:{}
    },
    getters: {
        loginUserInfo: (state) => state.loginUserInfo,
        loginUserOnlineStatus: (state) => state.loginUserOnlineStatus,
    },
    mutations: {
        CLOSE_WARNING_TIPS: (state) => (state.isShowWarningTips = false),
        CHANGE_LOGIN_STATUS: (state, status) => {
            state.loginState = status
        },
        CHANGE_NETWORK_STATUS: (state, status) => {
            state.networkStatus = status
        },

        SET_LOGIN_USER_INFO: (state, infos) => {
            state.loginUserInfo = Object.assign(state.loginUserInfo, infos)
        },
        SET_LOGIN_USER_ONLINE_STATUS: (state, payload) => {
            //console.log('payload', payload)
            state.loginUserOnlineStatus = payload
        },
        SET_customUserInfo: (state, payload) => {
          //console.log('payload', payload)
          state.customUserInfo = {...payload}
      },
    },
    actions: {
      // 获取登录用户的麦号熊系统用户信息
      getCustomUserInfo: async ({ commit }) => {
        // 重新获取取登录用户的麦号熊系统用户信息
        console.log("%c Line:57 🍡 重新获取取登录用户的麦号熊系统用户信息", "color:#ea7e5c");
        let userInfos = await getUserInfo()
        commit('SET_customUserInfo',userInfos.data)
        window.localStorage.setItem(`customUserInfo`, JSON.stringify(userInfos.data))
      },

    //获取登陆用户的用户属性
        getMyUserInfo: async ({ commit }, userId) => {
            const { data } = await EaseChatClient.fetchUserInfoById(userId)
            data[userId].hxId = userId
            commit('SET_LOGIN_USER_INFO', data[userId])
        },
        //修改登陆用户的用户属性
        updateMyUserInfo: async ({ commit }, params) => {
            const { data } = await EaseChatClient.updateUserInfo({ ...params })
            //console.log('>>>>>>修改成功', data)
            commit('SET_LOGIN_USER_INFO', data)
        },
        //处理在线状态订阅变更（包含他人的用户状态）
        handlePresenceChanges: ({ commit }, status) => {
            const { userId, ext: statusType } = status || {}
            if (userId === EaseChatClient.user) {
                commit(
                    'SET_LOGIN_USER_ONLINE_STATUS',
                    statusType ? statusType : 'Unset'
                )
            } else {
                //console.log('>>>>>>不是自己的状态')

                commit('SET_FRIEND_PRESENCE', [{ ...status }])
            }
        },
    },
    modules: {
        Conversation,
        Contacts,
        Message,
        Groups,
        Orders,
        QuickWords,
        UserRemark,
        afterSale
    },
})
